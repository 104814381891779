<template lang="html">
  <v-container fluid>
    <v-row>
      <v-col>
        <base-material-card icon="mdi-account-tie" color="red">

          <v-row>
            <v-col>
              <v-tabs v-model="tab">
                <v-tab>Semua</v-tab>
                <v-tab>Belum Deadline</v-tab>
                <v-tab>Lewat Deadline</v-tab>
              </v-tabs>
            </v-col>
          </v-row>

          <v-tabs-items v-model="tab">
            <v-tab-item>
              <crud
                :crudSettings="crudSettings"
                :headers="headers"
                :responseData="itemLim"
                :detailData="detailData"
                :forceUpdateTable="forceUpdate"
                @onTableChange="updateTable"
                @onDetailIKPClick="detailIKP"
                @onLaporIKPClick="laporIKP"
                @onValidasiIKPClick="validasiIKP"
              >
              </crud>
            </v-tab-item>
            <v-tab-item>
              <crud
                :crudSettings="crudSettings"
                :headers="headers"
                :responseData="itemLim"
                :detailData="detailData"
                :forceUpdateTable="forceUpdate"
                @onTableChange="updateTable"
                @onDetailIKPClick="detailIKP"
                @onLaporIKPClick="laporIKP"
                @onValidasiIKPClick="validasiIKP"
              >
              </crud>
            </v-tab-item>
            <v-tab-item>
              <crud
                :crudSettings="crudSettings"
                :headers="headers"
                :responseData="itemLim"
                :detailData="detailData"
                :forceUpdateTable="forceUpdate"
                @onTableChange="updateTable"
                @onDetailIKPClick="detailIKP"
                @onLaporIKPClick="laporIKP"
                @onValidasiIKPClick="validasiIKP"
              >
              </crud>
            </v-tab-item>
          </v-tabs-items>
        </base-material-card>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogDetailIKP" max-width="800px" >
      <v-card>
        <v-card-title>
          <span class="headline">Lihat Detail Laporan Realisasi IKP</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="formDetailIKP">
            <v-row>
              <v-col cols="3" align="left">
                <span >Leading Sector* :</span>
              </v-col>
              <v-col cols="9" align="left">
                  &nbsp;&nbsp;{{detailItem.kolok == null ? detailItem.unit_kerja_nama : detailItem.satuan_kerja_nama}}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3" align="left">
                <span >Supporting Sector* :</span>
              </v-col>
              <v-col cols="9" align="left">
                  <template v-for="s in detailItem.support_sector_nama">
                    <span class="mx-2" v-bind:key="s">{{s}}</span>
                    <br v-bind:key="s">
                  </template>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3" align="left">
                <span >Indikator* :</span>
              </v-col>
              <v-col cols="9" align="left">
                  &nbsp;&nbsp;{{detailItem.indikator+ ' ' +detailItem.satuan}}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3" align="left">
                <span >Tanggal Deadline* :</span>
              </v-col>
              <v-col cols="9" align="left">
                  &nbsp;&nbsp;{{detailItem.deadline}}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3" align="left">
                <span >Progress* :</span>
              </v-col>
              <v-col cols="9" align="left">
                  &nbsp;&nbsp;{{detailItem.progress}} %
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialogDetailIKP = false">Tutup</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogLapor" max-width="800">
      <v-card>
        <v-card-title>
          <span class="headline">Lapor Instruksi Khusus Pimpinan</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="formLapor">
            <!-- <h1 class="display-2 my-2">Lapor Instruksi Khusus Pimpinan</h1> -->
            <v-row>
              <v-col cols="3" align="left">
                <span >SKPD :</span>
              </v-col>
              <v-col cols="9" align="left">
                  <v-text-field dense v-model="formLapor.skpd" disabled outlined label="SKPD"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3" align="left">
                <span >Instruksi :</span>
              </v-col>
              <v-col cols="9" align="left">
                <!-- <v-text-field dense v-model="formLapor.instruksi" disabled outlined label="SKPD"></v-text-field> -->
                <span v-if="formLapor.instruksi">{{formLapor.instruksi}}</span>
                <span v-else>-</span>
              </v-col>
            </v-row>
            <v-row v-show="false">
              <v-col cols="3" align="left">
                <span >Tanggal Update :</span>
              </v-col>
              <v-col cols="9" align="left">
                <v-text-field dense v-model="formLapor.tanggal" disabled outlined label="SKPD"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3" align="left">
                <span >File Instruksi :</span>
              </v-col>
              <v-col cols="9" align="left"> 
                <div v-if="formLapor.attachment">
                  <template v-for="(f,i) in parseFilenameAttachment(formLapor.attachment)">
                    <div v-bind:key="i">Instruksi ke-{{i+1}} : <a class="font-weight-regular" :href="'https://kinerja.bandung.go.id/2022/img/lim/'+ (typeof f !== 'undefined' ? f : f)" target="_blank">{{(typeof f.name !== 'undefined' ? f : f)}}</a></div>
                  </template>
                </div>
                <div v-else>-</div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" align="left">
                <v-select dense v-model="formLapor.progress" :items="listProgress" item-text="text" item-value="value" label="Progress" outlined ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" align="left">
                <v-textarea v-model="formLapor.keterangan" :rules="[rules.required]" label="Ringkasan Laporan" outlined auto-grow></v-textarea>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" align="left">
                <span >File Evidence Yang Telah Diupload :</span>
                <div v-if="formLapor.file">
                  <template v-for="(f,i) in parseFilenameEvidence(formLapor.file)">
                    <div v-bind:key="i">Evidence ke-{{i+1}} : <a class="font-weight-regular" :href="'https://mangbagja.bandung.go.id/i/'+ (typeof f.name !== 'undefined' ? parseFilenameEvidence(f.name) : parseFilenameEvidence(f))" target="_blank">{{(typeof f.name !== 'undefined' ? parseFilenameEvidence(f.name) : parseFilenameEvidence(f))}}</a></div>
                  </template>
                </div>
                <div v-else>-</div>
              </v-col>
              <v-col cols="12" align="left">
                <file-input ref="fileInput" :helpText="'Evidence / Bukti Laporan (File XLS / DOC / PDF / JPG)'" :errorInput="fileInputErrorFlag" :uploadUrl="uploadUrl" @delFileUploaded="delFormFile" @fileUploaded="addFormFile"></file-input>
              </v-col>
            </v-row>

            <v-divider></v-divider>

          </v-form>
        </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="kirimLaporanIKP()" :loading="loadingBtn.laporkan" class="success" dark>Laporkan</v-btn>
        <v-btn @click="dialogLapor=false">Tutup</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="dialogValidasi" >
    <v-card>
      <ValidasiIKPDetail @update="refreshIKPTable" :dataIKP="dialogDataIKP"></ValidasiIKPDetail>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="dialogValidasi=false">Tutup</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  </v-container>
</template>

<script>
// import _ from 'lodash'
import store from '@/store'
import Crud from '@/components/common/Crud'
import IKPService from '@/services/IKPService'
import {defaultRules} from '@/utils/lib'
import FileInput from '@/components/common/FileInputIKP'
import { mapState } from 'vuex'
import ValidasiIKPDetail from './ValidasiIKPDetail'

export default {
  components:{
    Crud,
    FileInput,
    ValidasiIKPDetail
  },

  data(){
    return{
      forceUpdate:false,
      loading:false,
      tab: null,

      headers:[
        {text:'No', value:'index', sortable:false, class:'header-index-profile'},
        {text:'Instruksi', value:'instruksi', sortable:false, class:'header-index-profile'},
        {text:'Leading Sector', value:'leading', sortable:false, class:'header-index-profile'},
        {text:'Status', value:'status', sortable:false, class:'header-index-profile'},
        {text:'Tanggal Instruksi', value:'tanggal_ins', sortable:false, class:'header-index-profile'},
        {text:'Deadline', value:'deadline', sortable:false, class:'header-index-profile'},
        {text:'Tanggal Lapor', value:'tanggal_lpr', sortable:false, class:'header-index-profile'},
        {text:'Sisa Hari', value:'hari', sortable:false, class:'header-index-profile'},
        {text:'Aksi', value:'aksi_data_table_ikp', sortable:false}
      ],
      items:[],

      dialogReset:false,
      detailData:{},
      detailPegawai:{},
      itemUser:{},
      crudSettings:{
        title:'',
        detailTitle:'Detail',
        deleteTitleKey:'peg_nama',
        enableHeader:true,
        enableDetail:false,
        enableCreate:false,
      },
      itemLim:{},
      detailItem: {},
      dialogDetailIKP: false,
      dialogLapor: false,
      formLapor: {},
      loadingBtn:{
        laporkan:false,
      },
      rules:{
        ...defaultRules
      },
      listProgress: [
        {value:0, text: "Belum Selesai"},
        {value:10, text: "Progress: 10%"},
        {value:20, text: "Progress: 20%"},
        {value:30, text: "Progress: 30%"},
        {value:40, text: "Progress: 40%"},
        {value:50, text: "Progress: 50%"},
        {value:60, text: "Progress: 60%"},
        {value:70, text: "Progress: 70%"},
        {value:80, text: "Progress: 80%"},
        {value:90, text: "Progress: 90%"},
        {value:100, text: "Sudah Selesai"},
      ],
      fileInputErrorFlag:false,
      dialogValidasi: false,
      dialogDataIKP:[],
      id_lim_: null
    }
  },

  watch:{
    tab:function(val){
      var sorts = val == 0 ? 'all' : val == 1 ? 'belum' : 'lewat'
      var val_ = {itemsPerPage:10, page: 1, params:[]}
      this.forceUpdate = true
      this.updateTable(val_, sorts)
    }
  },

  created(){
    var a = this.$store.getters["user/data"].data_asn.jabatan
    var eselon = this.$store.getters["user/data"].data_asn.eselon_nm
    if(eselon.includes('II') || a.includes('Wali Kota')){
      if (a.includes('Kepala Badan') || a.includes('Kepala Dinas') || a.includes('Sekretaris Dewan') || a.includes('Kepala Satuan') || a.includes('Wali Kota')){
        let val = {itemsPerPage:10, page: 1, params:[]}
        this.updateTable(val, null)
      }
    }else if(eselon.includes('III')){
      if (a.includes('Camat')){
        let val = {itemsPerPage:10, page: 1, params:[]}
        this.updateTable(val, null)
      }
    }else{
      store.commit('snackbar/setSnack',{
            message:'Maaf, saat ini IKP hanya untuk kepala SKPD dan Camat.',
            color:'warning'})
      window.location.href = window.location.origin + '/#/dashboard'
    }
    // var kode_skpd = this.$store.getters["user/data"].data_asn["kolok"]
    // var skpds = ["4.03.01", "2.10.01","2.02.01"];
    // var base_url = window.location.origin
    // if(skpds.includes(kode_skpd)){
    //   let val = {itemsPerPage:10, page: 1, params:[]}
    //   this.updateTable(val, null)
    // }else{
    //   store.commit('snackbar/setSnack',{
    //         message:'Maaf, menu IKP sedang dalam perbaikan.',
    //         color:'warning'})
    //   let confirmAction = confirm("Maaf, menu IKP sedang dalam perbaikan.");
    //   if (confirmAction) {
    //     window.location.href = base_url + '/#/dashboard'
    //   } else {
    //     window.location.href = base_url + '/#/dashboard'
    //   }
    // }
    // let val = {itemsPerPage:10, page: 1}
    // this.updateTable(val)
  },

  computed:{
    uploadUrl(){
      return process.env.VUE_APP_API_URL + '/api/mb-upload/ikp/'
      // return process.env.VUE_APP_API_URL + '/api/ms/2/upload-evidence-skp-tahunan'
    },
    ...mapState({
      username: state=> state.user.current.username,
      user: state=> state.user.dataPegawai,
      current: state=> state.user.current
    }),
  },

  methods:{
    getImg(nip,ext){
      if (nip){
        let avatarImgUrl = process.env.VUE_APP_IMG_URL + nip +"."+ext
        // this.avatarImages[nip] = avatarImgUrl
        return avatarImgUrl
      }
      return ''
    },

    laporUlang(){},

    updateTable(val, sorts){
      this.tableValue = val ? val: this.tableValue
      this.tableValue.params['sorts'] = sorts
      IKPService.getAllIKP({page:this.tableValue.page,
        perpage:this.tableValue.itemsPerPage,
        search:this.tableValue.search,
        params:this.tableValue.params,
        order:'instruksi',
        order_direction:'',
        is_admin:false})
      .then((response)=>{
        this.itemLim = response.data
        this.eselon = response.data.eselon
        this.jft = response.data.jft
        console.log(this.itemLim)
      }).finally(()=>{
        this.loadingTahunan = false
      })
    },

    updateTableValidasi(val, id_lim){
      this.tableValue = val ? val: this.tableValue
      this.tableValue.params['id_lim'] = id_lim
      this.tableValue.search = ''
      IKPService.getListLaporSupportIKP({
        page:this.tableValue.page,
        perpage:this.tableValue.itemsPerPage,
        search:this.tableValue.search,
        params:this.tableValue.params,
        order:'',
        order_direction:'',
        id_lim:id_lim})
      .then((response)=>{
        this.dialogDataIKP = response.data.data
        console.log(this.dialogDataIKP)
      }).finally(()=>{
        this.loadingTahunan = false
      })
    },

    detailIKP(item){
      this.dialogDetailIKP = true
      this.detailItem = item
    },

    laporIKP(item){
      this.dialogLapor = true
      this.formLapor = item
      this.formLapor.keterangan = item.lapor.keterangan
      this.formLapor.file = item.lapor.file
    },

    validasiIKP(item){
      this.dialogValidasi = true
      var val_ = {itemsPerPage:10, page: 1, params:[]}
      this.id_lim_ = item.id_lim
      this.updateTableValidasi(val_, item.id_lim)
    },

    kirimLaporanIKP(){
      // this.formLapor.attachment = this.formLapor.attachment || []
      if (this.$refs.formLapor.validate() && this.formLapor.attachment.length > 0){
        this.loadingBtn.laporkan = true
        IKPService.laporIKP(this.formLapor).then(response=>{
          let res = response.data
          if (res.success){
            store.commit('snackbar/setSnack',{message: res.message, color:'success'})
            this.dialogLapor = false
            this.updateTable()
          }else {
            store.commit('snackbar/setSnack',{message: res.message, color:'error'})
          }
        }).finally(()=>{
          this.loadingBtn.laporkan = false
        })
      }else{
        this.loadingBtn.laporkan = false
        store.commit('snackbar/setSnack',{message: 'Maaf, Form Anda Belum Lengkap. Silahkan Lengkapi Form Lapor IKP dan Upload Bukti/Evidence Anda. Terima Kasih!', color:'warning'})
      }
    },

    refreshIKPTable(){
      var val_ = {itemsPerPage:10, page: 1, params:[]}
      this.updateTableValidasi(val_, this.id_lim_)
    },

    delFormFile(file){
      let regex = /\d+_([\w\s-]+)-[\w\d]+\.(\w+)/
      let filename = file.file.name
      this.formLapor.attachment = this.formLapor.attachment.filter((val)=>{
        const match = val.match(regex)
        let curr_filename = match[1]+'.'+match[2]
        if (match){
          return filename != curr_filename
        }
        return false
      })
    },

    addFormFile(file){
      this.formLapor.attachment.push(file)
      console.log(this.formLapor.attachment)
    },

    parseFilenameEvidence(filename){
      var arr = []
      try{
        if(filename != undefined){
          let parsedFilename = JSON.parse(filename)
          if(parsedFilename.length > 0){
            for (let i = 0; i < parsedFilename.length; i++) {
              const element = parsedFilename[i];
              if(element.name != undefined){
                arr.push(element.name)
              }else{
                arr.push(element)
              }
            }
          }
          return arr
        }else{
          return 
        }
      } catch(e){
        return filename
      }
    },
    parseFilenameAttachment(filename){
        return filename
    },

  },
}
</script>

<style lang="css" scoped>
</style>
