<template lang="html">
  <v-container fluid>
    <v-card>
      <v-card-title>
        <span class="headline">Validasi Instruksi Khusus Pimpinan Supporting Sectors</span>
      </v-card-title>
      <div class="py-5" />
      <v-simple-table>
        <thead>
          <th>No</th>
          <th>Supporting Sector/SKPD</th>
          <th>Keterangan</th>
          <th>Data Pendukung</th>
          <th>Aksi</th>
        </thead>
        <tbody>
          <template v-if="dataIKP.length > 0">
            <template v-for="(item,i) in dataIKP">
              <tr :key="i+'a'">
                <td>{{(i+1)}}</td>
                <td>{{ item.nama_skpd != null ? item.nama_skpd : item.unit_kerja_nama }}</td>
                <td style="width:20%">{{ item.keterangan }}</td>
                <td>
                  <br>
                  <template v-for="(f,i) in parseFilename(item.file)">
                    <div v-bind:key="i">
                      Evidence ke-{{i+1}} : <br>
                      <a v-bind:href="'https://mangbagja.bandung.go.id/i/'+ (typeof f.name !== 'undefined' ? parseFilename(f.name) : parseFilenameAttachment(f))" target="_blank">{{(typeof f.name !== 'undefined' ? f.name : f)}}</a>
                    </div>
                    atau 
                    <div v-bind:key="i">
                      <a v-bind:href="'https://kinerja.bandung.go.id/2022/img/lim/'+ (typeof f.name !== 'undefined' ? parseFilename(f.name) : parseFilenameAttachment(f))" target="_blank">{{(typeof f.name !== 'undefined' ? f.name : f)}}</a>
                    </div>
                    <br v-bind:key="i">
                  </template>
                  <br>
                </td>
                <td>
                  <confirm-btn
                    title="Terima"
                    content="Apakah anda yakin <b>MENERIMA</b> laporan IKP ini?"
                    :data="{...item, respon:'terima'}"
                    :btnProps="{name:'Terima', color:'success', icon:'mdi-check', small:true, class:{'ma-1':true} }"
                    :cardProps="{color:'success lighten-1'}"
                    @on-agree="simpanValidasiIKP"
                    class="ma-1"></confirm-btn>
                  <confirm-btn
                    title="Tolak"
                    content="Apakah anda yakin <b>MENOLAK</b> laporan IKP ini?"
                    :data="{...item, respon:'tolak'}"
                    :btnProps="{name:'Tolak', color:'error',icon:'mdi-close', small:true, class:{'ma-1':true} }"
                    :cardProps="{color:'error lighten-1'}"
                    @on-agree="simpanValidasiIKP"
                    class="ma-1"></confirm-btn>
                    <!-- <TextBtn @on-agree="simpan" title="Terima" :data="{...item, jenis:'terima',key:i+'a'}" :btnProps="{name:'Terima', color:'success', icon:'mdi-check', small:true, class:{'ma-1':true} }" class="ma-1"></TextBtn>
                    <TextBtn @on-agree="simpan" title="Tolak" :data="{...item, jenis:'tolak', key:i+'a'}" :btnProps="{name:'Tolak', color:'error',icon:'mdi-close', small:true, class:{'ma-1':true} }" class="ma-1"></TextBtn> -->
                </td>
              </tr>
            </template>
          </template>
          <template v-else>
            <tr>
              <td :colspan="4">Tidak ada data.</td>
            </tr>
          </template>
        </tbody>
      </v-simple-table>
    </v-card>
  </v-container>
</template>

<script>
import _ from 'lodash'
import store from '@/store'
import IKPService from '@/services/IKPService'
import moment from 'moment'
// import TextBtn from './components/TextBtn'
import ConfirmBtn from '@/components/base/ConfirmBtn'

export default {
  components:{
    ConfirmBtn
    // TextBtn
  },

  props:{
    dataIKP:Array,
  },

  data(){
    return {
      hides:{},
      // ikps:[]
    }
  },

  filters:{
    addZero: function(value) {
      if (value * 1 < 10) {
        return '0' + value
      }
      return value
    },

    formatTime: function(value){
      moment.locale('id')
      return moment(value).format('dddd, Do MMMM YYYY');
    }
  },

  mounted(){
    // this.ikps = _.clone(this.dataIKP)
  },

  methods:{

    findId(isi_id){
      var i,j
      for (i=0;i < this.ikps.length; i++){
        let isi = this.ikps[i].isi_aktifitas
        for (j=0;j < isi.length; j++){
          console.log(isi[j])
          if(isi[j].id == isi_id){
            return {isi: isi[j], i: i,j:j}
          }
        }
      }
      return
    },
    simpanValidasiIKP(item){
      IKPService.validasiSupportingIKP(item).then(response=> {
        let res = response.data
        if (res.status){
          store.commit('snackbar/setSnack',{message:res.message, color:'success'})
        } else {
          store.commit('snackbar/setSnack',{message:res.message, color:'error'})
        }
      }).finally(()=>{
        this.$emit('update')
      })
    },

    parseFilename(filename){
      let parsedFilename_ = JSON.parse(filename)
      var parsedFilename = null
      if(typeof parsedFilename_ == "string"){
        parsedFilename = JSON.parse(parsedFilename_)
      }else{
        parsedFilename = parsedFilename_
      }
      try{
        return parsedFilename
      } catch(e){
        return filename
      }
    },
    parseFilenameAttachment(filename){
        return filename
    },
  }
}
</script>

<style lang="css" scoped>
</style>
